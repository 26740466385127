<template>
  <div class="reportPage analysisInheritPayment">
    <section>
      <div class="reportTitle">
        <h2>상속세 납부여력 분석</h2>
      </div>

      <div class="analysisInheritTable">
        <div>
          <table class="nowInherit">
          <tbody>
            <tr>
              <th colspan="2">현재시점 기준</th>
            </tr>
            <tr>
              <td>상속재산</td>
              <td class="tar pointBlack">{{ $comma(report6.nowFirstInheritanceTax.inheritanceAmount) }}만원</td>
            </tr>
            <tr class="borderTop">
              <td>상속세</td>
              <td class="tar pointOrange">{{ $comma(report6.nowFirstInheritanceTax.finalTaxAmount) }}만원</td>
            </tr>
            <tr class="borderTop">
              <td>현금성 상속재산</td>
              <td class="tar pointOrange">{{ $comma(report6.nowFirstInheritanceTax.totalFinancialAssetsAmountExceptLifeInsurance + report6.nowFirstInheritanceTax.lifeInsuranceAmount) }}만원</td>
            </tr>
            <tr>
              <td colspan="2">
                <div class="inner">
                  <div class="subItem">
                    <p class="normal">금융재산</p>
                    <b class="tar">{{ $comma(report6.nowFirstInheritanceTax.totalFinancialAssetsAmountExceptLifeInsurance) }}만원</b>
                  </div>
                  <div class="subItem">
                    <p class="normal">종신보험금</p>
                    <b class="tar">{{ $comma(report6.nowFirstInheritanceTax.lifeInsuranceAmount) }}만원</b>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
          <div class="resultComment">
            현재 시점 상속 시,<br/>
            상속세 납부 위해 필요한 금액은 {{ $comma(report6.nowFirstInheritanceTax.finalTaxAmount) }}만원,<br/>
            부족금액은 <b>{{ $comma(report6.nowFirstInheritanceTax.shortfallAmount) }}만원</b>입니다.
          </div>
        </div>

        <div>
          <table class="nowInherit">
          <tbody>
            <tr>
              <th colspan="2">상속시점 기준</th>
            </tr>
            <tr>
              <td>상속재산</td>
              <td class="tar pointBlack">{{ $comma(report6.firstInheritanceTaxExpectationYearsLater.inheritanceAmount) }}만원</td>
            </tr>
            <tr class="borderTop">
              <td>상속세</td>
              <td class="tar pointOrange">{{ $comma(report6.firstInheritanceTaxExpectationYearsLater.finalTaxAmount) }}만원</td>
            </tr>
            <tr class="borderTop">
              <td>현금성 상속재산</td>
              <td class="tar pointOrange">{{ $comma(report6.firstInheritanceTaxExpectationYearsLater.totalFinancialAssetsAmountExceptLifeInsurance + report6.firstInheritanceTaxExpectationYearsLater.lifeInsuranceAmount) }}만원</td>
            </tr>
            <tr>
              <td colspan="2">
                <div class="inner">
                  <div class="subItem">
                    <p class="normal">금융재산</p>
                    <b class="tar">{{ $comma(report6.firstInheritanceTaxExpectationYearsLater.totalFinancialAssetsAmountExceptLifeInsurance) }}만원</b>
                  </div>
                  <div class="subItem">
                    <p class="normal">종신보험금</p>
                    <b class="tar">{{ $comma(report6.firstInheritanceTaxExpectationYearsLater.lifeInsuranceAmount) }}만원</b>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
          <div class="resultComment">
            {{ report6.expectationYearsLater }}년 후 상속 시,<br/>
            상속세 납부 위해 필요한 금액은 {{ $comma(report6.firstInheritanceTaxExpectationYearsLater.finalTaxAmount) }}만원,<br/>
            부족금액은 <b>{{ $comma(report6.firstInheritanceTaxExpectationYearsLater.shortfallAmount) }}만원</b>입니다.
          </div>
        </div>
      </div>

      <div
        class="noticeComment asterisk"
        v-if="viewMode === 2"
      >
        회사는 상품에 대하여 충분히 설명할 의무가 있으며, 고객님께서는 이에 대한 설명을 들을 의무가 있습니다.<br/>
        상품 가입시 자세한 사항은 상품설명서 및 약관을 참조하시기 바랍니다.
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "AnalysisInheritPayment",
  props: {
    viewMode: {
      type: Number
    }
  },
  computed: {
    report6: {
      get() {
        return this.$store.getters.getReport6
      }
    },
  },
  methods:{
    toFixed(v, n) {
      return parseFloat(v.toFixed(n))
    },
  },
  mounted() {
    // console.log("report6 ::: ", this.report6)
  }
}
</script>
