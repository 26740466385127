<template>
  <div class="reportPage inheritTaxDetail">
    <section>
      <div class="reportTitle">
        <h2>예상 상속재산 세부사항</h2>
      </div>

      <div class="detailData">
        <table class="detailTable">
          <tbody>
            <tr>
              <th colspan="2">구분</th>
              <th class="borderNone"></th>
              <th>현재</th>
              <th class="borderNone"></th>
              <th>상속시점({{ report2.expectationYearsLater }}년 후)</th>
            </tr>
            <tr class="fullLine">
              <td>부동산자산</td>
              <td></td>
              <td></td>
              <td class="tar">
                {{
                  $comma(
                      report3.nowFirstInheritanceTax.assets.토지.amount +
                      report3.nowFirstInheritanceTax.assets.주택.amount +
                      report3.nowFirstInheritanceTax.assets.건물.amount
                  )
                }}
                만원
              </td>
              <td></td>
              <td class="tar">
                {{
                  $comma(
                      report3.firstInheritanceTaxExpectationYearsLater.assets.토지.amount +
                      report3.firstInheritanceTaxExpectationYearsLater.assets.주택.amount +
                      report3.firstInheritanceTaxExpectationYearsLater.assets.건물.amount
                  )
                }}
                만원
              </td>
            </tr>
            <tr class="fullLine">
              <td colspan="3">가업주식</td>
              <td class="tar">{{ $comma(report3.nowFirstInheritanceTax.assets.가업주식.amount) }} 만원</td>
              <td class="borderNone"></td>
              <td class="tar">{{ $comma(report3.firstInheritanceTaxExpectationYearsLater.assets.가업주식.amount) }} 만원</td>
            </tr>
            <tr>
              <td rowspan="5">기타금융자산</td>
              <td>예금</td>
              <td class="borderNone"></td>
              <td class="tar">{{ $comma(report3.nowFirstInheritanceTax.assets.예금.amount) }} 만원</td>
              <td class="borderNone"></td>
              <td class="tar">{{ $comma(report3.firstInheritanceTaxExpectationYearsLater.assets.예금.amount) }} 만원</td>
            </tr>
            <tr>
              <td>주식</td>
              <td class="borderNone"></td>
              <td class="tar">{{ $comma(report3.nowFirstInheritanceTax.assets.주식.amount) }} 만원</td>
              <td class="borderNone"></td>
              <td class="tar">{{ $comma(report3.firstInheritanceTaxExpectationYearsLater.assets.주식.amount) }} 만원</td>
            </tr>
            <tr>
              <td>기타금융자산</td>
              <td class="borderNone"></td>
              <td class="tar">{{ $comma(report3.nowFirstInheritanceTax.assets.기타금융자산.amount) }} 만원</td>
              <td class="borderNone"></td>
              <td class="tar">{{ $comma(report3.firstInheritanceTaxExpectationYearsLater.assets.기타금융자산.amount) }} 만원</td>
            </tr>
            <tr>
              <td>
                <p>
                  보험/보험자산<br/>
                  (납입보험료 또는 해지환급금)
                </p>
              </td>
              <td class="borderNone"></td>
              <td class="tar">{{ $comma(report3.nowFirstInheritanceTax.assets.보험자산.amount) }} 만원</td>
              <td class="borderNone"></td>
              <td class="tar">{{ $comma(report3.firstInheritanceTaxExpectationYearsLater.assets.보험자산.amount) }} 만원</td>
            </tr>
            <tr>
              <td>사망보험금(종신보험)</td>
              <td class="borderNone"></td>
              <td class="tar">{{ $comma(report3.nowFirstInheritanceTax.assets.종신보험금.amount) }} 만원</td>
              <td class="borderNone"></td>
              <td class="tar">{{ $comma(report3.firstInheritanceTaxExpectationYearsLater.assets.종신보험금.amount) }} 만원</td>
            </tr>
            <tr class="fullLine">
              <td colspan="3">기타자산</td>
              <td class="tar">{{ $comma(report3.nowFirstInheritanceTax.assets.기타자산.amount) }} 만원</td>
              <td class="borderNone"></td>
              <td class="tar">{{ $comma(report3.firstInheritanceTaxExpectationYearsLater.assets.기타자산.amount) }} 만원</td>
            </tr>
            <tr>
              <td rowspan="2">과세가액</td>
              <td>장례비용</td>
              <td class="borderNone"></td>
              <td class="tar">{{ $comma(report3.nowFirstInheritanceTax.assets.장례비용.amount) }} 만원</td>
              <td class="borderNone"></td>
              <td class="tar">{{ $comma(report3.firstInheritanceTaxExpectationYearsLater.assets.장례비용.amount) }} 만원</td>
            </tr>
            <tr>
              <td>채무</td>
              <td class="borderNone"></td>
              <td class="tar">{{ $comma(report3.nowFirstInheritanceTax.assets.채무.amount) }} 만원</td>
              <td class="borderNone"></td>
              <td class="tar">{{ $comma(report3.firstInheritanceTaxExpectationYearsLater.assets.채무.amount) }} 만원</td>
            </tr>
            <tr class="fullLine sumData">
              <td></td>
              <td colspan="2">총 상속 과세액</td>
              <td class="tar">{{ $comma(report3.nowFirstInheritanceTax.inheritanceAmount) }} 만원</td>
              <td></td>
              <td class="tar">{{ $comma(report3.firstInheritanceTaxExpectationYearsLater.inheritanceAmount) }} 만원</td>
            </tr>
            <tr>
              <td rowspan="4">공제금액</td>
              <td>배우자공제</td>
              <td class="borderNone"></td>
              <td class="tar">{{ $comma(report3.nowFirstInheritanceTax.deductions.배우자공제.amount) }} 만원</td>
              <td class="borderNone"></td>
              <td class="tar">{{ $comma(report3.firstInheritanceTaxExpectationYearsLater.deductions.배우자공제.amount) }} 만원</td>
            </tr>
            <tr>
              <td>일괄공제</td>
              <td class="borderNone"></td>
              <td class="tar">{{ $comma(report3.nowFirstInheritanceTax.deductions.일괄공제.amount) }} 만원</td>
              <td class="borderNone"></td>
              <td class="tar">{{ $comma(report3.firstInheritanceTaxExpectationYearsLater.deductions.일괄공제.amount) }} 만원</td>
            </tr>
            <tr>
              <td>금융자산공제</td>
              <td class="borderNone"></td>
              <td class="tar">{{ $comma(report3.nowFirstInheritanceTax.deductions.금융자산공제.amount) }} 만원</td>
              <td class="borderNone"></td>
              <td class="tar">{{ $comma(report3.firstInheritanceTaxExpectationYearsLater.deductions.금융자산공제.amount) }} 만원</td>
            </tr>
            <tr>
              <td>동거주택상속공제</td>
              <td class="borderNone"></td>
              <td class="tar">{{ $comma(report3.nowFirstInheritanceTax.deductions.동거주택상속공제.amount) }} 만원</td>
              <td class="borderNone"></td>
              <td class="tar">{{ $comma(report3.firstInheritanceTaxExpectationYearsLater.deductions.동거주택상속공제.amount) }} 만원</td>
            </tr>
            <tr class="fullLine sumData">
              <td></td>
              <td colspan="2">총 공제금액</td>
              <td class="tar">{{ $comma(report3.nowFirstInheritanceTax.totalDeductionsAmount) }} 만원</td>
              <td></td>
              <td class="tar">{{ $comma(report3.firstInheritanceTaxExpectationYearsLater.totalDeductionsAmount) }} 만원</td>
            </tr>
            <tr class="fullLine summaryData">
              <td></td>
              <td colspan="2">총 상속 과세액</td>
              <td class="tar">{{ $comma(report3.nowFirstInheritanceTax.inheritanceAmount) }} 만원</td>
              <td></td>
              <td class="tar">{{ $comma(report3.firstInheritanceTaxExpectationYearsLater.inheritanceAmount) }} 만원</td>
            </tr>
            <tr class="fullLine summaryData">
              <td></td>
              <td colspan="2">최종 상속세액</td>
              <td class="tar">{{ $comma(report3.nowFirstInheritanceTax.finalTaxAmount) }} 만원</td>
              <td></td>
              <td class="tar">{{ $comma(report3.firstInheritanceTaxExpectationYearsLater.finalTaxAmount) }} 만원</td>
            </tr>
            <tr class="fullLine summaryData">
              <td></td>
              <td colspan="2">세후 상속재산</td>
              <td class="tar">{{ $comma(report3.nowFirstInheritanceTax.inheritanceAmountAfterPayTax) }} 만원</td>
              <td></td>
              <td class="tar">{{ $comma(report3.firstInheritanceTaxExpectationYearsLater.inheritanceAmountAfterPayTax) }} 만원</td>
            </tr>
          </tbody>
        </table>
        <div class="announce">
          <em>
            <div class="icoAnnounce">
              <img src="@/assets/img/report/icoInfo.svg" alt="">
            </div>
            <div class="subject">
              <p>상속시점 자산 평가</p>
              <div>
                상속시점 평가액은 임의 입력된 연간 가치 상승률에 따라
                계산되었습니다.
              </div>
            </div>

            <div class="subject">
              <p>공제금액</p>
              <div class="asterisk">
                배우자 상속공제 : 최소 5억원에서 실제 상속받은 금액
                내에서 공제실제 상속 받은 금액은 최고 30억원과 배우자의
                민법상 상속지분에 의해 계산된 한도 적용<br/>
                <i>(※ 실제 상속받은 금액이 없거나 5억원 미만인 경우5억원 적용)</i>
              </div>

              <div class="asterisk">
                일괄공제 : 기본 5억원 공제
              </div>

              <div class="asterisk">
                금융자산공제 : 순금융재산가액 2천만원 이하인 경우 전액,
                2천만원을 초과하는 경우 순금융재산가액의 20%<br/>
                <i>(단, 2천만원 미달 시 2천만원, 2억원 초과시 2억원)</i>
              </div>

              <div class="asterisk">
                동거주택상속공제 : 동거주택가격 or 6억원 중 적은 금액 공제<br/>
                <i>(동거주택의 미래 가격 변동은 반영되지 않음)</i>
              </div>
            </div>

            <div class="subject">
              <p>최종 상속세액</p>
              <div>
                과세표준액(총 상속 과세액 – 총 공제금액)에 따라<br/> 세율이 확정되며, 누진공제 및 신고세액공제(3%) 적용<br/>
                <i>(상속세율 기준표 참고)</i>
              </div>
            </div>
          </em>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name:"InheritTaxDetail",
  computed: {
    report2: {
      get() {
        return this.$store.getters.getReport2
      }
    },
    report3: {
      get() {
        return this.$store.getters.getReport3
      }
    },
  },
  mounted() {
    // console.log("report3 ::: ",this.report3)
  },
  methods: {
  }
}
</script>
