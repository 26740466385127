<template>
  <div class="reportPage nowInheritAssets">
    <section>
      <div class="reportTitle">
        <h2>상속 시점 예상 상속재산</h2>
        <button
          class="inheritRateTable"
          @click="openInheritReferenceLayerPopup"
        >
          <img src="@/assets/img/report/icoInheritRateTable.png" alt="">
          상속세율 기준표
        </button>
      </div>

      <div class="calculateTable reportNowInherit">
        <table>
          <tbody>
            <tr>
              <td>
                <div class="calculateCell">
                  <div class="cellTitle">{{ report2.expectationYearsLater }}년 후</div>
                  <div class="cellData">
                    고객연령 {{ report2.expectationYearsAge }}세
                  </div>
                </div>
              </td>
              <td>
                <div class="calculateCell">
                  <div class="cellTitle">총 상속 재산</div>
                  <div class="cellData">
                    {{ $comma(report2.totalPropertiesAmount) }}만원
                  </div>
                </div>
              </td>
              <td>
                <div class="calculateCell">
                  <div class="cellTitle">채무</div>
                  <div class="cellData">
                    {{ $comma(report2.financialObligationsAmount) }}만원
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="calculateCell">
                  <div class="cellTitle">상속인</div>
                  <div class="cellData heir">
                    배우자 {{ report2.spouseExist === true ? "있음" : "없음" }}<br/>
                    {{ report2.additionalInheritorType }} {{ report2.additionalInheritorCount }}명
                  </div>
                </div>
              </td>
              <td>
                <div class="calculateCell">
                  <div class="cellTitle">총 상속 과세액</div>
                  <div class="cellData tax">
                    {{ $comma(report2.inheritanceAmount) }}만원
                  </div>
                </div>
              </td>
              <td>
                <div class="calculateCell">
                  <div class="cellTitle">총 상속 세액</div>
                  <div class="cellData tax">
                    {{ $comma(report2.finalTaxAmount) }}만원
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="calculateResult">
          <div class="calculateResultBox">
            <div class="calculateResultTitle">세후 상속 재산</div>
            <div class="calculateResultTitleExp">총 상속 과세액 - 총 상속 세액</div>
            <div class="totalInherit">
              {{ $comma(report2.inheritanceAmountAfterPayTax) }}만원
            </div>
          </div>
        </div>
      </div>

      <div class="resultTable">
        <div class="resultTableTitle">
          {{ report2.expectationYearsLater }}년 후 상속 재산
        </div>
        <div class="resultData" :class="additionalInheritorType === 1 ? 'linealAscendant' : ''">
          <div
              class="resultItems"
              v-for="(inheritor, inheritorIdx) in report2.inheritorList"
              :key="inheritorIdx"
          >
            <div class="itemTitle">{{ inheritor.type === "배우자" ? inheritor.type : inheritor.name }}</div>
            <ul class="itemData">
              <li class="taxData1">
                <p>상속과세액</p>
                <b>{{ $comma(inheritor.inheritanceAmount) }}만원</b>
              </li>
              <li class="taxData2">
                <div>
                  유류분 {{ $comma(inheritor.reserveInheritanceAmount) }}만원
                </div>
              </li>
              <li class="taxData3">
                <p>상속세액</p>
                <b>{{ $comma(inheritor.finalTaxAmount) }}만원</b>
              </li>
              <li class="taxData4">
                <p>세후상속</p>
                <b>{{ $comma(inheritor.inheritanceAmountAfterPayTax) }}만원</b>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "InheritTimingAssets",
  props: {
    userInfo: {
      type: String,
      require: true
    }
  },
  computed: {
    additionalInheritorType: {
      get() { return this.$store.getters.getAdditionalInheritorType }
    },
    report2: {
      get() {
        return this.$store.getters.getReport2
      }
    },
  },
  methods: {
    openInheritReferenceLayerPopup () {
      this.trackRateTable()
      this.$emit('inheritReferenceLayerPopup', true)
    },
    trackRateTable() {
      const parameterUser = {'employeeNumber': this.userInfo}
      window.gtag('event', 'Action_inheritRateRefererTable', parameterUser)
    }
  },
  mounted() {
    // console.log("report2 ::: ",this.report2)
  }
}
</script>

