<template>
  <div class="reportPage nowInheritAssets">
    <section>
      <div class="reportTitle">
        <h2>현재 시점 예상 상속재산</h2>
        <button
            class="inheritRateTable"
            @click="openInheritReferenceLayerPopup"
        >
          <img src="@/assets/img/report/icoInheritRateTable.png" alt="">
          상속세율 기준표
        </button>
      </div>
      <div class="calculateTable reportNowInherit">
        <table>
          <tbody>
          <tr>
            <td>
              <div class="calculateCell">
                <div class="cellTitle">현재 시점</div>
                <div class="cellData">
                  고객연령 {{ report1.age }}세
                </div>
              </div>
            </td>
            <td>
              <div class="calculateCell">
                <div class="cellTitle">총 상속 재산</div>
                <div class="cellData">
                  {{ $comma(report1.totalPropertiesAmount) }}만원
                </div>
              </div>
            </td>
            <td>
              <div class="calculateCell">
                <div class="cellTitle">채무</div>
                <div class="cellData">
                  {{ $comma(report1.financialObligationsAmount) }}만원
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="calculateCell">
                <div class="cellTitle">상속인</div>
                <div class="cellData heir">
                  배우자 {{ report1.spouseExist === true ? "있음" : "없음" }}<br/>
                  {{ report1.additionalInheritorType }} {{ report1.additionalInheritorCount }}명
                </div>
              </div>
            </td>
            <td>
              <div class="calculateCell">
                <div class="cellTitle">총 상속 과세액</div>
                <div class="cellData tax">
                  {{ $comma(report1.inheritanceAmount) }}만원
                </div>
              </div>
            </td>
            <td>
              <div class="calculateCell">
                <div class="cellTitle">총 상속 세액</div>
                <div class="cellData tax">
                  {{ $comma(report1.finalTaxAmount) }}만원
                </div>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
        <div class="calculateResult">
          <div class="calculateResultBox">
            <div class="calculateResultTitle">세후 상속 재산</div>
            <div class="calculateResultTitleExp">총 상속 과세액 - 총 상속 세액</div>
            <div class="totalInherit">
              {{ $comma(report1.inheritanceAmountAfterPayTax) }}만원
            </div>
          </div>
        </div>
      </div>

      <div class="resultTable">
        <div class="resultTableTitle">
          현재 시점 상속 재산
        </div>
        <div class="resultData" :class="additionalInheritorType === 1 ? 'linealAscendant' : ''">
          <div
            class="resultItems"
            v-for="(inheritor, inheritorIdx) in report1.inheritorList"
            :key="inheritorIdx"
          >
            <div class="itemTitle">{{ inheritor.type === "배우자" ? inheritor.type : inheritor.name }}</div>
            <ul class="itemData">
              <li class="taxData1">
                <p>상속과세액</p>
                <b>{{ $comma(inheritor.inheritanceAmount) }}만원</b>
              </li>
              <li class="taxData2">
                <div>
                  유류분 {{ $comma(inheritor.reserveInheritanceAmount) }}만원
                </div>
              </li>
              <li class="taxData3">
                <p>상속세액</p>
                <b>{{ $comma(inheritor.finalTaxAmount) }}만원</b>
              </li>
              <li class="taxData4">
                <p>세후상속</p>
                <b>{{ $comma(inheritor.inheritanceAmountAfterPayTax) }}만원</b>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "NowInheritAssets",
  props: {
    userInfo: {
      type: String,
      require: true
    }
  },
  computed: {
    additionalInheritorType: {
      get() { return this.$store.getters.getAdditionalInheritorType }
    },
    report1: {
      get() {
        return this.$store.getters.getReport1
      }
    },
  },
  mounted() {
    // console.log("report1 ::: ", this.report1)
  },
  methods: {
    openInheritReferenceLayerPopup () {
      this.trackRateTable()
      this.$emit('inheritReferenceLayerPopup', true)
    },
    trackRateTable() {
      const parameterUser = {'employeeNumber': this.userInfo}
      window.gtag('event', 'Action_inheritRateRefererTable', parameterUser)
    }
  }
}
</script>

