<template>
  <div class="reportPage firstInheritEstimate">
    <section>
      <div class="reportTitle">
        <h2>1차 상속세 예상 추이</h2>
      </div>

      <div class="reportEstimateData">
        <div class="estimateTable">
          <table class="nowInheritTax">
            <tbody>
              <tr>
                <th colspan="5">
                  <div class="inheritTax">
                    <p>현재 시점 상속 과세액 <span>({{report4.expectationYearsAge - report4.expectationYearsLater}}세)</span></p>
                    <b>{{ $comma(report4.nowFirstInheritanceTax.inheritanceAmount) }}만원</b>
                  </div>
                </th>
              </tr>
              <tr>
                <td>총 상속세</td>
                <td class="borderNone"></td>
                <td>{{ $comma(report4.nowFirstInheritanceTax.finalTaxAmount) }}만원</td>
                <td class="borderNone"></td>
                <td>{{ toFixed((report4.nowFirstInheritanceTax.finalTaxAmount / report4.nowFirstInheritanceTax.inheritanceAmount) * 100, 2) }}%</td>
              </tr>
              <tr>
                <td>세후 재산 총액</td>
                <td class="borderNone"></td>
                <td>{{ $comma(report4.nowFirstInheritanceTax.inheritanceAmountAfterPayTax) }}만원</td>
                <td class="borderNone"></td>
                <td>{{ toFixed((report4.nowFirstInheritanceTax.inheritanceAmountAfterPayTax / report4.nowFirstInheritanceTax.inheritanceAmount) * 100, 2) }}%</td>
              </tr>
            </tbody>
          </table>

          <div class="later">
            {{ report4.expectationYearsLater }} 년 후
          </div>

          <table class="estimatedInheritTax">
            <tbody>
              <tr>
                <th colspan="5">
                  <div class="inheritTax">
                    <p>예상 시점 상속 과세액 <span>({{report4.expectationYearsAge}}세)</span></p>
                    <b>{{ $comma(report4.firstInheritanceTaxExpectationYearsLater.inheritanceAmount) }}만원</b>
                  </div>
                </th>
              </tr>
              <tr>
                <td>총 상속세</td>
                <td class="borderNone"></td>
                <td>{{ $comma(report4.firstInheritanceTaxExpectationYearsLater.finalTaxAmount) }}만원</td>
                <td class="borderNone"></td>
                <td>{{ toFixed((report4.firstInheritanceTaxExpectationYearsLater.finalTaxAmount / report4.firstInheritanceTaxExpectationYearsLater.inheritanceAmount) * 100, 2) }}%</td>
              </tr>
              <tr>
                <td>세후 재산 총액</td>
                <td class="borderNone"></td>
                <td>{{ $comma(report4.firstInheritanceTaxExpectationYearsLater.inheritanceAmountAfterPayTax) }}만원</td>
                <td class="borderNone"></td>
                <td>{{ toFixed((report4.firstInheritanceTaxExpectationYearsLater.inheritanceAmountAfterPayTax / report4.firstInheritanceTaxExpectationYearsLater.inheritanceAmount) * 100, 2) }}%</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="estimateGraph">
          <div class="verticalChartBox">
            <div class="chartInfo">
              <ul>
                <li><span/> 상속세</li>
                <li><span/> 세후 상속재산</li>
              </ul>
            </div>
            <div class="chartBox">
              <div class="default">고객 연령</div>
              <div class="chartInner">
                <div class="inheritAge">
                  <div
                    class="dottedLine"
                    :style="`left: ${ageLine}%`"
                  />
                  <div
                    class="inheritAgeInfo"
                    :style="`left: ${ageLine}%`"
                  >
                    예상 상속시점<br/>
                    (고객 {{report4.firstInheritanceTaxExpectationYearsLater.age}}세)
                    <span></span>
                  </div>
                </div>
                <ul class="axis_x">
                  <li
                      class="item"
                      v-for="(value, valueIdx) in report4.firstInheritanceTaxList"
                      :key="valueIdx"
                      :style="`
                      height:${ 50 + liHeight(value.inheritanceAmount, report4.firstInheritanceTaxList[report4.firstInheritanceTaxList.length - 1].inheritanceAmount) }%;
                      `"
                  >
                    <div class="text_box">
                      <span class="axisTitle">{{value.age}}세</span>
                    </div>
                    <div class="graph">
                    <span
                        class="bar data1"
                        :style="`height:${ toFixed(value.inheritanceAmountAfterPayTax / value.inheritanceAmount * 100, 1) }%;`"
                    >
                      <b>
                        {{ $comma(value.inheritanceAmountAfterPayTax) }} 만원<br/>
                        <b>{{ toFixed(value.inheritanceAmountAfterPayTax / value.inheritanceAmount * 100, 1) }}%</b>
                      </b>
                    </span>
                      <span
                          class="bar data2"
                          :style="`height:${ toFixed((value.finalTaxAmount / value.inheritanceAmount) * 100, 1) }%;`"
                      >
                      <b>{{ $comma(value.finalTaxAmount) }} 만원</b>
                    </span>
                      <span class="total">
                      총 과세액 <br/>
                      {{ $comma(value.inheritanceAmount) }}만원
                    </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "FirstInheritEstimate",
  computed: {
    report4: {
      get() {
        return this.$store.getters.getReport4
      }
    },
    ageLine() {
      return (1 - (100 - this.report4.firstInheritanceTaxExpectationYearsLater.age) / (100 - this.report4.nowFirstInheritanceTax.age)) * 100
    }
  },
  methods:{
    toFixed(v, n) {
      return parseFloat(v.toFixed(n))
    },
    liHeight(inheritanceAmount, maxInheritanceAmount) {
      if (inheritanceAmount <= 0) {
        return 0
      } else {
        return 50 * this.toFixed(inheritanceAmount / maxInheritanceAmount, 1)
      }
    }
  },
  mounted() {
    // console.log("report4 ::: ", this.report4)
  }
}
</script>
